export default {
  name: '姓名',
  address: '地址',
  id_number: '身分證字號',
  vat_number: '統一編號',
  // address: '戶籍地址',
  registered_address: '登記地址',
  mailing_address: '通訊地址',
  phone: '連絡電話',
  company_name: '公司名',
  owner: '代表人',
  cancel: '取消',
  confirm: '確定',
  checkout: '結帳',
  songWallet: '歌曲交易紀錄',
  wallet: '錢包',
  purchased: '購買紀錄',
  plzChoice: '請選擇',
  required: '必填',
  priceAmount: '售價金額',
  musicNotice: '請輸入授權於一個國家網路廣告音樂價格，其他參考',
  scaleTable: '比例表',
  paymentHeader: '填寫付款資訊',
  walletDetailHeader: {
    __tradingDay: '交易日',
    __buySongs: '售出歌曲',
    __priceAmount: '金額',
    __customerName: '客戶名',
    __note: '備註',
    __positive: '歌曲金額',
    __negative: '提領金額'

  },
  orderDetailHeader: {
    __tradingDay: '交易日',
    __transactionItem: '交易項目',
    __depositWithdraw: '已付金額',
    __downloadFile: '檔案下載',
    __note: '備註',
    __member: '會員方案',
    __memberCancel: '取消訂閱',
    __song: '歌曲購買',
    __coupon: '使用券',
    __record: '混音組合'
  },
  videoErrorType: '上傳的格式是 {type}, 影片請輸入 MP4 檔',
  imageErrorType: '上傳的格式是 {type}, 相片請輸入 JPEG 或 PNG 檔',
  imageErrorType2: '檔案格式不符, 相片請輸入 JPEG 檔',
  contractCheckBox: '我已詳閱且同意',
  Copyright: '著作權授權合約書',
  personal: '個人',
  enterprise: '企業',
  roles: '角色',
  allItem: '全部',
  transferIn: '轉入',
  transferOut: '轉出',
  system: {
    alert: {
      __confirm: '確定'
    },
    input: {
      placeholder: '請輸入...',
      required: '必填'
    },
    autocomplete: {
      placeholder: '請選擇'
    },
    __login: '請先登入',
    __disableMobile: '手機版服務開發中，敬請期待'
  },
  all: {
    userIdentity: {
      __chooseYourIdentity: '選擇你的專長',
      __musician: '音樂創作者',
      __photographer: '影像創作者',
      __confirm: '確定'
    },
    navList: {
      __aboutUs: '關於我們',
      aboutUs: {
        __home: '影音配對',
        __creators: '影像創作',
        __dearMusician: '給音樂人的信',
        __membership: '會員方案'
      },
      __filmMusicMapping: '影像配樂',
      user: {
        __film: '影像管理',
        __music: '音樂人專區',
        // __profile: '個 人 中 心',
        // __chooseYourIdentity: '切 換 角 色',
        __signOut: '登 出'
      },
      faq: {
        __title: '常見問題',
        __path: 'https://avmapping.co/zh/blog/a.v.-mapping-%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/'
      },
      __upgrade: 'Upgrade to Pro',
      currency: {
        __twd: '台幣',
        __usd: '美金',
        __eur: '歐元'
      }
    },
    mobile: {
      menu: {
        __aboutUs: '關於我們',
        __home: '影音配對',
        __creators: '影像創作',
        __dearMusician: '給音樂人的信',
        __membership: '會員方案',
        __filmMusicMapping: '影像配樂',
        __film: '影像管理',
        __music: '音樂人專區'
      },
      __chooseYourIdentity: '切換角色',
      __signOut: '登出'
    }
  },
  filmMusicMapping: {
    step1: {
      __title: '動態視覺分析',
      __uploadFilm: '上傳你的影片(限mp4格式,小於50MB)',
      __errorYoutube: '請確認 Youtube 網址是否正確。',
      __errorNoMusic: '您填入的預算範圍 或 參數組合沒有適合選項，請擴大範圍並重新輸入。',
      __alertMappingMsg: '請稍待片刻… 完成後系統將通知您。',
      __alertMappingNote: '在分析未完成前：\n1. 若需同時上傳其他影片，需開啟「新的分頁」進行。\n2. 若離開本頁面，則分析結果將直接存入「我的影像庫」，不再提供「步驟二－編輯頁面」。',
      __finishMappingMsg: '您的影片已經分析好囉，快來看看吧！',
      __confirmButton1: '分析新影片',
      __confirmButton2: '關閉視窗',
      buttonGroup: {
        __confirm: '確定',
        __cancel: '取消',
        title: {
          __title: '影片名稱'
        },
        story: {
          __title: '輸入劇情大綱',
          __note: '輸入劇情大綱'
        },
        referenceMusic: {
          __title: '參考音樂',
          __note: '- 請輸入 youtube 網址',
          __alert: '請放 youtube 連結'
        },
        budgetRange: {
          __title: '預算範圍',
          __placeholder: '請輸入指定金額',
          __min: '最低預算 ≧ 1',
          __max: '最高預算 ≦ 2B',
          __validateError: '預算上限為2,000,000,000，請重新輸入',
          __currency: '(NTD)',
          __hint: '小提醒：我們的音樂都來自專業音樂人授權，所以找不到合適的時候可以嘗試將預算範圍拉高哦！'
        },
        licenseUsage: {
          __title: '使用範圍',
          __commercial: '商業',
          __noncommercial: '非商業'
        },
        licenseArea: {
          __title: '授權區域',
          __region_type: '授權區域類型',
          __region: '授權區域',
          __country: '授權國家'
        },
        licenseTime: {
          __title: '授權時間'
        },
        spec: {
          __title: '特殊需求',
          __instrument: '樂器',
          __genre: '風格',
          __emotion: '情緒',
          speed: {
            __title: '速度',
            __note: '請輸入 BPM (20 - 220)'
          },
          other: {
            __title: '其他',
            __note: '請輸入其他特殊需求'
          }
        }
      },
      startAnalysis: {
        __submitNotice: '送出即算一次費用',
        __start: '開始分析',
        __alert1: '請上傳影片',
        __alert2: '有必填項目 (使用範圍, 授權區域, 授權時間) 未填',
        __uploading: '影片上傳中，請稍後...<br>已上傳',
        __uploadFinish: '上傳完畢！開始分析...',
        __analyzing: '分析中...',
        __budgetError: '最高預算必須大於最低預算',
        __noiseError: '手動輸入資訊超過影片時長，請重新檢查',
        __noiseEndError: '噪音結束時間必須小於或等於結束時間',
        __effectError: '手動輸入資訊超過影片時長，請重新檢查'
      },
      lack: {
        __slogan: 'We are sorry...',
        __message: `很抱歉，目前音樂資料庫尚缺乏符合您需求的音樂，<br>
        我們會持續努力，擴大音樂資料庫，以滿足您的喜好。<br>
        <br>
        如果您想推薦喜歡的音樂類別，歡迎分享愛飛媒平給音樂人，<br>
        同時幫助他們輕鬆獲取被動收入！<br>
        <br>
        感謝您使用我們的服務，期待您下次光臨。`,
        __share: '立即分享',
        __shareMessage: `【至親好友限定分享】我想推薦您至A.V. Mapping 上架販售您的音樂，讓最專業的平台幫助您擁有自動成為影像配樂的機會，除了廣讓作品被看見，同時也提供高額的分潤給您，成為被動收入，讓我們一起分享出去👉${process.env.VUE_APP_HOME_URL}{locale}/?recommend={id}#register`,
        __copyMessage: '連結已複製到剪貼簿',
        __coupon: '使用券折抵申請',
        __couponUrl: 'https://www.surveycake.com/s/94Nwz',
        __analysis: '重新分析',
        __close: '關閉'
      }
    },
    step2: {
      __title: '動 態 視 覺 分 析',
      __alert: '請注意：尚未取得版權前，擅自分享錄影，將觸及著作權法與社群規範，也將導致帳號被停用，謝謝。',
      songBlock: {
        keyword: {
          __title: 'Keywords'
        },
        songs: {
          __title: 'Songs',
          check: {
            __note: '勾選以確認購買',
            __checked: '已勾選'
          }
        }
      },
      result: {
        __confirm: '確認購買',
        __reanalyse: '重新分析'
      },
      alert: {
        __confirm: '確認',
        __msg: '請至少選一首歌',
        __noise: '本支影片沒有聲音，恕不提供降噪服務。'
      },
      adjust: {
        __likeMusic: '您有喜歡的配樂嗎？',
        __unlike: '我都不喜歡',
        __request: '可以調整您的特殊需求'
      }
    }
  },
  filmMusicMappingV2: {
    step1: {
      __title: '請選擇你要進行的服務',
      __cancel: '取消',
      __complete: '完成',
      __alert: '請放 Youtube 連結',
      __placeholder: '輸入參考音樂的 YouTube 網址以找到相似音樂',
      __limit: '上傳檔案需小於 50 MB',
      __durationLimit: '線上傳輸影片限制在一小時內，有需求請聯繫本公司',
      __isLive: '目前不支援上傳直播中影片，有需求請聯繫本公司',
      file: {
        __upload: '上傳影片 / 相片 / 音檔',
        __uploadWithoutAudio: '上傳影片 / 相片',
        __uploadMusic: '上傳音檔',
        __drag: '上傳或拖曳影片 / 相片 / 音檔',
        __dragVillage: '上傳或拖曳影片 / 相片',
        __youtube: '輸入參考音樂的 YouTube 網址以找到相似音樂'
      },
      service: {
        soundtrack: {
          __title: 'AI 影像配樂',
          __content: [
            '省去六個月時間',
            '直接取得音樂人核可合約',
            '自動將您的相片變影片'
          ]
        },
        effect: {
          __title: '自動音效',
          __content: [
            '用Video audio AI為您設計音效'
          ]
        },
        reduction: {
          __title: 'AI 聲音降噪',
          __content: [
            'AI 聲音降噪迅速處理您影片中的雜音',
            '再也無須花費大量時間人工處理'
          ]
        }
      },
      uploadFilm: {
        __uploading: '影片上傳中，請稍後...<br>已上傳',
        __uploadFinish: '上傳完畢！'
      },
      alert: {
        __wait: `非常榮幸在這裡見到您！
        但是很遺憾的，我們現在有大量的影片正在排隊中，我們正在努力的讓他們跑得更快速
        您還是可以上傳影片或照片，讓他分析，我們會在完成後通知您
        或是麻煩請您明天再來！
        （可能需要12小時的時間，但還是比原本的六個月音樂授權還要來得快些😂）

        再見，請享受您的美好時光。☕`
      }
    },
    'step1-2': {
      __alertMappingMsg: '請稍待片刻… 完成後系統將通知您。',
      __alertMappingNote: '在分析未完成前：\n1. 若需同時上傳其他影片，需開啟「新的分頁」進行。\n2. 若離開本頁面，則分析結果將直接存入「我的影像庫」，不再提供「步驟二－編輯頁面」。',
      __confirmButton1: '分析新影片',
      __confirmButton2: '關閉視窗',
      __errorNoMusic: '您填入的預算範圍 或 參數組合沒有適合選項，請擴大範圍並重新輸入。',
      __errorYoutube: '請確認 Youtube 網址是否正確。',
      __finishMappingMsg: '您的影片已經分析好囉，快來看看吧！',

      __cancel: '清除',
      __confirm: '確定',
      __name: '名稱',
      __placeholder: '請輸入檔案名稱',
      __selectPlaceholder: '未選擇',
      __type: '類型',
      usage: {
        __usage: '使用用途',
        __platform: '使用平台'
      },
      buttonGroup: {
        algorithm: {
          __title: '演算法'
        },
        budget: {
          __title: '價格預算'
        },
        budgetRange: {
          __hint: '小提醒：我們的音樂都來自專業音樂人授權，所以找不到合適的時候可以嘗試將預算範圍拉高哦！',
          __max: '最高預算 ≦ 2B',
          __min: '最低預算 ≧ 1'
        },
        licenseArea: {
          __title: '授權地區',
          __type: '授權地區類型',
          __region: '授權區域',
          __country: '授權國家'
        },
        linceseTime: {
          __title: '授權時間'
        },
        licenseUsage: {
          __commercial: '商業',
          __noncommercial: '非商業',
          __title: '使用用途'
        },
        mobile: {
          __required: '加入必填',
          __optional: '加入選填'
        }
      },
      soundtrack: {
        __head: 'AI 影像配樂',
        __title: '配樂',
        __titleV1: '其他需求',
        __add: '新增配樂',
        __edit: '編輯 Cue Sheet',
        options: {
          __time: '插入時間',
          __ref: '參考音樂',
          __outline: '劇情大綱',
          __keyword: '關鍵字',
          __other: '其他',
          __instrument: '樂器',
          __genre: '音樂風格',
          __mood: '情緒',
          __bpm: 'BPM',
          __lang: '語言'
        }
      },
      effect: {
        __head: '自動音效',
        __type: '音效類型',
        __title: '音效',
        __add: '新增音效',
        __edit: '編輯 Cue Sheet',
        options: {
          __time: '插入時間',
          __object: '插入物件',
          __action: '插入動作',
          __unfilled: '未填'
        }
      },
      reduction: {
        __head: 'AI 聲音降噪',
        __capture: '噪音發生的時間',
        __noise: 'Noise Reduction',
        __add: '新增降噪'
      },
      startAnalysis: {
        __analyzing: '分析中',
        __start: '開始分析',

        error: {
          __budget: '最高預算必須大於最低預算',
          __effect: '手動輸入資訊超過影片時長，請重新檢查',
          __noise: '手動輸入資訊超過影片時長，請重新檢查',
          __noiseEnd: '噪音結束時間必須小於或等於結束時間',
          __notAvailable: '選擇的演算法無法在此檔案上使用',
          __refRequired: '使用 Mosaic 演算法時，必須提供參考音樂作為影像配樂服務的依據'
        }
      },
      analysis: {
        __start: '開始分析',
        __reanalyze: '重新分析',
        __cancel: '取消'
      },
      algorithm: {
        __whatever: ' 都好',
        __sonnet: ' 小歌謠 : 重情緒與字詞分析',
        __sonata: ' 奏鳴曲：著重影像分析、整合音樂分類分析',
        __mosaic: ' 共鳴：著重參考音樂的相似度分析'
      }
    },
    step2: {
      __soundtrack: 'AI 影像配樂(M)',
      __effect: '自動音效(S)',
      __reduction: 'AI 聲音降噪(N)',
      __reductionTips: '您的影片沒有聲音、噪音，無須降噪。',
      __total: '總價',
      __currency: 'NTD',
      __more: '想自己選',
      __reanalyze: '重新分析',
      __prev: '上一步',
      __next: '下一步',
      __checkout: '前往結帳',
      __copied: '已複製連結',
      alert: {
        __unconnected: '目前有狀況出錯、連線不穩定，請稍後再試',
        __single: '每個類別至多僅能單選，請更改清單',
        __effectLimit: '音效最多只能選擇三首'
      },
      order: {
        __title: '您的購物清單',
        single: {
          __title: '單買'
        },
        mixer: {
          __add: '新增混音組合',
          __content: '點選混音組合包含的項目',
          __title: '混音組合',
          // __content: '點選組合包含的項目，確認後按複製即可產出搭配影片的試聽連結',
          __recordVideo: '製作影片',
          __recordAudio: '單純混音'
        }
      },
      share: {
        __title: '試聽連結',
        __copied: '已複製連結'
      }
    },
    checkout: {
      __count: '相同歌曲不重複計價',
      __invoice: '輸入統一編號',
      __cancel: '取消',
      __confirm: '付款',
      check: {
        __content: '本人特此聲明已詳細閱讀「上述所有音樂授權合約」並已充分了解且同意遵守全部內容。',
        __alert: '請詳細閱讀聲明內容後勾選'
      },
      service: {
        __soundtrack: 'AI 影像配樂(M)',
        __effect: '自動音效(S)',
        __reduction: 'AI 聲音降噪(N)',
        __mixer: '混音組合'
      },
      invoice: {
        __global: 'Invoice(Global)',
        __taiwan: '電子發票(台灣)',
        __tip: '合約將帶入公司資訊'
      },
      promo: {
        __title: '我有優惠碼',
        __placeholder: '輸入優惠碼',
        __exist: '您輸入的優惠碼不存在',
        __remove: '移除',
        __blank: '優惠碼不可為空白'
      },
      message: {
        __fail: '過程中有東西出錯了，請再試一次',
        __film: '過程中有東西出錯了，請再試一次(1)', // 使用者沒有該 film 的權限
        __profile: '過程中有東西出錯了，請再試一次(2)', // 指定的 profile 不屬於該使用者
        __missing: '過程中有東西出錯了，請再試一次(3)', // 指定的 profile 資料不完整
        __taxID: '統一編號不符合規範',
        __purchased: '選擇的商品中已有被購買過的商品',
        __promo: '您輸入的優惠碼不存在',
        __used: '您輸入的優惠碼已經使用過',
        __least: '沒有選擇任何商品'
      },
      tips: {
        __title: '為 AI 來點服務費',
        __sub: '服務費',
        __content: '請選擇服務費',
        proportion: {
          first: {
            __value: '15',
            __text: '不錯'
          },
          second: {
            __value: '18',
            __text: '很好'
          },
          third: {
            __value: '20',
            __text: '滿意'
          },
          fourth: {
            __value: '25',
            __text: '很喜歡'
          },
          five: {
            __value: '30',
            __text: '這服務太棒了'
          },
          custom: {
            __value: '自訂',
            __text: ''
          }
        }
      },
      alert: {
        __content: '確定刪除嗎？\n我們會同時刪掉混音組合',
        __confirm: '確定',
        __cancel: '取消'
      }
    },
    download: {
      tab: {
        __soundtrack: 'AI 影像配樂(M)',
        __effect: '自動音效(S)',
        __reduction: 'AI 聲音降噪(N)',
        __record: '混音組合'
      },
      button: {
        __library: '返回 Library',
        __file: '下載影音檔案',
        __contract: '下載合約',
        __record: '下載混音組合',
        __recordWithVideo: '下載影片與混音組合'
      }
    },
    search: {
      __premium: 'Premium',
      __search: 'Search',
      __slogan: 'AI Finds Your Music, Quick and Easy.',
      tips: {
        __alert: 'Please enter YouTube link',
        __file: 'Upload or drag your video / photo',
        __keyword: 'Keywords | Scripts | Plot Summary',
        __youtube: 'Enter YouTube link'
      },
      intro: {
        __slogan: 'AI finds your music',
        __title1: 'VIDEO & IMAGES TO MUSIC',
        __content1: 'Find the perfect match music through understanding the video & images you uploaded',
        __title2: 'MUSIC TO MUSIC',
        __content2: 'From emotion, genre, musicality to reference music, AI finds the music closes to your preference.',
        __title3: 'TEXT TO MUSIC',
        __content3: 'From text, scripts, plot summary, synopsis, CUE sheet to music!',
        __button: 'See More'
      },
      contact: {
        __email: 'service@avmapping.co',
        __slogan: 'Contact us'
      }
    }
  },
  user: {
    navList: {
      __personalInformation: '基本資料',
      __contract: '簽署活動與聯盟合約',
      __myMusicList: '我的歌曲列表',
      __uploadMusic: '上傳歌曲',
      __myFilmList: '我的影像庫',
      __myWallet: '我的錢包',
      __transactionDetail: '我的交易明細',
      __signOut: '登出'
    },
    userInfo: {
      __introduction: '簡介',
      __website: '個人網站',
      __email: '電子郵件',
      __country: '所在國家',
      __edit: '編輯個人檔案',
      __save: '儲存個人檔案',
      __note:
        '・點選「編輯個人檔案」按鈕即可編輯基本資料，填寫完後，請按下「儲存個人檔案」按鈕以儲存資料。',
      __editComplete: '編輯完成',
      __noResult: '無資料',
      more: '顯示更多',
      less: '顯示更少',
      membership: {
        __title: '會員方案',
        __free: '試用方案',
        __promo: '優惠方案',
        __business: '企業方案',
        __advanced: '進階方案',
        __personal: '個人方案',
        __nonCommercial: '非商用方案',
        __basic: '一般會員',
        __note: '還有 {msg} 天，請',
        __noMembership: '您沒有方案，請升級正式會員。',
        __update: '[立即升級👉]'
      },
      message: {
        __success: '訂閱成功'
      }
    },
    mySong: {
      __title: '我的歌曲列表',
      songDetail: {
        price: {
          __title: '訂定價格',
          __currency: 'USD'
        },
        __artist: '作者',
        __bpm: '節奏（BPM）',
        __chroma: 'chroma',
        __composer: '作曲',
        __instrument: '樂器',
        __key: 'key',
        __language: '語別',
        __lyricist: '作詞',
        __mood: '情緒',
        __musicGenre: '曲風',
        __name: '歌曲名稱',
        __op: 'OP 公司',
        __price: '訂定價格（USD）',
        __singer: '歌手',
        __sp: 'SP 公司',
        __tag: 'Tag',
        __title: '歌曲名稱',
        __uploadDate: '上傳日期',

        __releaseTitle: '要將作品上鏈 Web 3.0',
        __releaseValue: '(額外加選) 我要將作品上鏈、用不可竄改的方式追蹤我的作品流向（需另外付費）',
        __trainingTitle: '同意授權予 AI 訓練',
        __trainingValue: '(建議選取) 我同意 AI 使用本資料作為訓練資料',

        answer: {
          __yes: '是',
          __no: '否'
        },

        ipfs: {
          __beingProcessed: '音樂相關資料已上傳至 IPFS 或正在進行 IPFS 相關處理 (上傳中或刪除中)',
          __dataMissing: '下單前請先填寫合約基本資訊',
          __subscription: '成功訂閱',
          __subscriptionFailed: '訂閱失敗',
          __unsubscribe: '成功取消訂閱',

          content: {
            __committing: '；正在上傳歌曲的檔案與資訊至 IPFS 上',
            __committed: '；歌曲已上傳至 IPFS，NID：',
            __canceling: '；取消中，系統處理中',
            __canceled: '；取消完成，會員截止日：',
            __deleting: '；歌曲資料正從 IPFS 上移除',
            __deleted: '；歌曲資料已從 IPFS 上移除'
          }
        }
      },
      alert: {
        __content: '請問您是否確定要刪除歌曲？',
        __note: '注意：此項動作無法復原',
        __cancel: '否，請帶我回去',
        __confirm: '是，請刪除'
      }
    },
    myFilm: {
      __title: '我的影像庫',
      __incompleteMessage: '請稍待分析，分析結果將於完成後通知您',
      __inactiveMessage: '請點選開始分析',
      message: {
        __1: '歐喔，這個月好像還沒上傳影片哦',
        __2: '從這邊開始上傳影片、圖片或文字，以找尋最適合的音樂跟音效吧',
        __3: '或是輸入參考音樂的YouTube連結，可以找到類似的、有版權的音樂',
        __4: '* 別忘了按「開始分析」',
        __5: '分析過後，你可以在這一頁看到你的結果',
        __6: 'AVMapping 非常注重保護您的隱私。上傳的影音檔30天後自動刪除，以保護你的影像資料安全與隱私。'
      },
      filmList: {
        __mappingDate: '配對時間',
        __mappingDeadline: '配對期限',
        __currency: 'USD',
        mappingMusicStatus: {
          __toPurchase: '我要購買',
          __purchased: '我已購買'
        }
      },
      filmDetail: {
        __backMyFilm: '返回影像庫列表',
        __10MappingSong: '為你挑選 {num} 首配樂',
        __isBought: '已購買',
        __reselect: '開始分析',
        __purchase: '結帳',
        __step2: '編輯',
        tips: {
          __like: '按愛心切換未儲存清單',
          __dislike: '按愛心切換儲存清單',
          __purchase: '選擇音樂名前往結帳',
          __mappingLimit: '配樂最多只能選擇三首'
        }

      }
    },
    uploadSong: {
      __title: '上傳歌曲',
      alert: {
        __limit: '上傳格式限 wav 或 mp3',
        __chooseFile: '請選擇要上傳的檔案',
        // __songName: '請輸入歌曲名稱',
        // __artistName: '請輸入作者名稱',
        // __price: '請輸入訂定價格（USD）',
        __contractCheck: '請先閱讀授權書'
        // __bpm: '節奏（BPM） 不得小於 20',
        // __emotion: '請輸入音樂情緒',
        // __genre: '請輸入音樂曲風',
      },
      fileInput: {
        __upload: '上傳檔案或將檔案拖曳到這裡',
        __tips: '※ 上傳格式限 wav 或 mp3，位元傳輸率 192kbps 以上'
      },
      uploading: {
        __alert:
          '儲存中... 你的檔案正在上傳！在完成之前請不要關閉這個頁面......',
        __finished: '上傳完畢',
        __cancel: '取消上傳'
      },
      songData: {
        __name: '歌曲名稱',
        __price: '訂定價格',
        __instrument: '樂器',
        __lyricist: '作詞',
        __composer: '作曲',
        __singer: '歌手',
        __musicGenre: '曲風',
        __mood: '情緒',
        __bpm: '節奏（BPM）',
        __language: '語別',
        __sp: 'SP 公司',
        __op: 'OP 公司'
      },
      contract: {
        __title: 'A.V. Mapping 媒合平台服務合約',
        __agree: '本人特此聲明已詳細閱讀「著作權財產委任代理契約」並已充分了解且同意遵守全部內容。',
        __alert: '請詳細閱讀所有內容後勾選',
        __content: [
          '本人向社團法人 A.V. Mapping 所登記管理之音樂著作，均係據實申報，絕無任何虛構匿飾之情事，如有第三人向 A.V. Mapping 主張該等音樂著作之權利，本人應依照與 A.V. Mapping 所簽訂之音樂著作財產權管理契約(下稱管理契約)第六條之規定負擔排除侵害或賠償之責任。',
          '本人同意，A.V. Mapping 管理地區為依管理契約之約定，惟如本人於本作品登記表中有指定代理地區，A.V. Mapping 將依指定代理地區為之，但本人於管理契約所約定之責任義務不受影響。'
        ]
      },
      card: {
        month: {
          __button: 'Per music per month',
          __price: '60 NTD',
          __title: 'Monthly Package',
          __value: 'month',
          list: {
            __1: '$ 1.5 per music when over 100 music',
            __2: '$ 1 per music when over 1,000 music',
            __3: 'Under 25MB',
            __4: 'Extra fee if more than 25MB',
            __5: 'AI label and analysis',
            __6: 'Cloud computing and IPFS storage',
            __7: 'DDEX format adjustment',
            __8: 'After cancellation, the data would be unpin, and lose the connection on the chain.',
            __9: 'Including IPFS storage and Web 3.0 gas fee',
            __10: 'Tax and cash platform service fee'
          }
        },
        year: {
          __button: 'Per music per year',
          __price: '600 NTD',
          __title: 'Yearly Package',
          __value: 'year',
          list: {
            __1: '$ 15 Over 100 music',
            __2: '$ 12 Over 1,000 music',
            __3: '20% off than monthly package',
            __4: 'AI label and analysis',
            __5: 'Cloud computing and IPFS storage',
            __6: 'DDEX format adjustment',
            __7: 'After cancellation, the data would be unpin, and lose the connection on the chain. ',
            __8: 'Including IPFS storage and Web 3.0 gas fee',
            __9: 'Tax and cash platform service fee'
          }
        },
        edit: {
          list: {
            __1: 'When update the information each time(except for the 1st time)'
          }
        }
      },
      __uploadConfirm: '確認上傳'
    },
    myWallet: {
      __title: '我的錢包',
      info: {
        money: {
          __currentWallet: '目前錢包',
          __totalAmount: '共賺了',
          __currency: 'NTD'
        },
        mapping: {
          __successMapping: '已經成功配對',
          __unit: '部影片'
        },
        analysis: {
          __count: '剩餘使用次數',
          __unit: '次 / 本月'
        }
      },
      btn: {
        __transactionDetails: '查看交易明細',
        __deposit: '購買方案',
        __withdraw: '我要提款',
        __promo: '優惠碼分享',
        __exchange: '兌換序號',
        __success: '兌換成功',
        __unsubscribe: '取消訂閱'
      },
      __note: [
        '可以到我的交易明細內查詢錢包歷史紀錄。',
        '未提領金額滿 6000 NTD 以上，就可以向我們提款。',
        '本金流是經由 NewebPay 金流系統。'
      ],
      __promo: {
        header: '想要擁有更多使用券，享受更多服務嗎？',
        content: [
          '推薦朋友體驗，雙方即可各自享有一張使用券喔！',
          // eslint-disable-next-line no-template-curly-in-string
          '（一張使用券價值 ${amount} 元）'
        ],
        promoCode: '您的推薦序號',
        button: '複製序號',
        alt: '優惠碼分享'
      },
      __exchange: {
        placeholder: '輸入兌換序號',
        button: '輸入',
        error: '序號錯誤！'
      },
      __success: {
        // eslint-disable-next-line no-template-curly-in-string
        content: '您已經獲得一張價值 ${amount} 元的使用券！',
        alt: '兌換成功'
      },
      message: {
        __success: '您已成功取消訂閱。',
        __noActive: '請購買訂閱方案，目前沒有任何方案。',
        __beenCancel: '您目前沒有任何方案。'
      }
    },
    transactionDetail: {
      __title: '我的交易明細',
      query: {
        __transaction: '查詢項目',
        __detailsOfCharge: '明細類型',
        __dateRange: '日期區間',
        __query: '立即查詢'
      },
      __note: [
        '說明：',
        '1. 本系統提供最近一年之交易明細查詢,次查詢之起日~迄日的設定區間最長為六個月,查詢超過六個月以上之交易明細,分次查詢。',
        '2. 下載的歷史檔案只保留3天，請於有效期限內下載。',
        '3. 本頁資料不得作為存款餘額證明或往來憑證之用。'
      ],
      result: {
        __back: '返回明細查詢',
        download: {
          __contract: '合約',
          __music: '音樂',
          __receipt: '發票、收據',
          __video: '影片',
          __xml: 'Xml'
        },
        table: {
          title: {
            __history: '購買紀錄',
            __musicTradeRecords: '歌曲交易紀錄',
            __sum: '共',
            __no: '第',
            __records: '筆',
            __pages: '頁'
          },
          header: {
            musician: {
              __serialNumber: '序號',
              __tradingTime: '交易日',
              __transactionItem: '交易項目',
              __depositWithdraw: '存入/提扣款',
              __balance: '帳戶結餘',
              __note: '備註'
            },
            photographer: {
              __serialNumber: '序號',
              __tradingTime: '交易日',
              __transactionItem: '交易項目',
              __depositWithdraw: '存入/提扣款',
              __balance: '帳戶結餘',
              __note: '備註'
            }
          }
        }
      }
    },
    uploadContract: {
      __agree: '本人特此聲明，已確認資料填寫無誤，已詳細閱讀「A.V. Mapping: FAIM Concert Agreement」並已充分了解且同意遵守全部內容。',
      __confirm: '確認上傳',
      __download: 'The Agreement',
      __edit: '編輯資料',
      __more: 'Know More',
      __title: '簽署活動與聯盟合約',

      current: {
        __film: '影像管理',
        __music: '音樂人專區'
      },

      alert: {
        __check: '請詳細閱讀所有內容後勾選',
        __identity: '請選擇身分別',
        __missing: '請先填寫合約基本資訊',
        __success: 'Thanks for signing up “FAIM Fest.” Please keep in tune with us.',
        __upload: '請先閱讀授權書'
      }
    }
  },
  errors: {
    __beenUploaded: '這首音樂已存在於我司資料庫，請確認您是否重新上傳。',
    __invalid: 'The Youtube url is invalid',
    __login: '登入錯誤，請回首頁重新登入',
    __mixerMissing: 'The mixer record data is missing',
    __unavailable: 'The Youtube url is unavailable',
    __unavailableEffect: 'Unavailable sound effect',
    __unavailableFile: 'Unavailable file',
    __unavailableMusic: 'Unavailable music',
    __unavailableMusicProfile: '請填寫正式授權資訊供簽署正確合約。',
    __unavailableSubscription: '請購買相關會員方案。',
    __unknown: '由於YouTube的版權政策的關係，我們沒辦法使用這支音樂作為分析。請嘗試其他影片或音樂連結',
    __validated: '電子郵件需要先進行驗證'
  },
  lackOfMusic: {
    __wrongType: '補償格式錯誤',
    __alreadySent: '此次分析結果補償的使用券已發送',
    __overLimit: '已達每月補償使用券上限'
  },
  dashboard: {
    button: {
      __platform: '上架到其他平台',
      __preference: '測試您的喜好',
      __work: ['分享作品', '提高收益'],
      __referral: ['推薦碼分享'],
      __upload: ['上傳音樂以取得收益']
    }
  },
  library: {
    nav: {
      __project: '進行中專案',
      __music: '音樂庫',
      __finished: '完成品'
    },
    music: {
      __project: 'Save & Likes',
      __projectName: '專案名',
      __soundtrackName: '歌曲名',
      __purchased: '已購買的音樂',
      __uploaded: '已上傳的音樂',
      __uploadButton: '上傳音樂 →'
    },
    finished: {
      __test: '測試您的喜好 →',
      __create: '製作您的故事分鏡 →',
      __browse: '瀏覽他人作品'
    }
  },
  music: {
    __like: '只顯示喜愛的音樂，打開 ♥',
    __title: 'Music Library',
    budget: {
      __min: '最低預算 ≧ 1',
      __max: '最高預算 ≦ 2B'
    },
    buttons: {
      __confirm: '確定',
      __cancel: '清除',
      __start: 'Start free mapping'
    },
    license: {
      __selectPlaceholder: '未選擇',
      __type: '類型',
      area: {
        __country: '授權國家',
        __region: '授權區域',
        __title: '授權地區',
        __type: '授權地區類型'
      },
      time: {
        __title: '授權時間'
      },
      usage: {
        __commercial: '商業',
        __noncommercial: '非商業',
        __platform: '使用平台',
        __title: '使用用途'
      }
    },
    options: {
      __artist: '作者',
      __bpm: 'BPM',
      __budget: '價格預算',
      __currency: '(NTD)',
      __genre: '音樂風格',
      __instrument: '樂器',
      __keyword: '關鍵字',
      __lang: '語言',
      __emotion: '情緒',
      __outline: '劇情大綱',
      __ref: '參考音樂'
    }
  },

  profile: {
    __contractIdentity: '身分別',

    options: {
      __affiliation: 'Affiliation Program',
      __audience: 'Audience',
      __brand: 'Brand',
      __media: 'Media, News',
      __musicians: 'Musicians',
      __sponsors: 'Sponsors',
      __superStars: 'Super Stars',
      __technology: 'Technology Company',
      __visualCreators: 'Visual Creators (Filmmakers, Animator, Artists, Youtubers etc.)',
      __web3: 'Web 3.0 Project'
    }
  },

  sign: {
    up: {
      __title: '創建您的帳戶',
      __free: '開始 14 天免費試用',
      __email: '電子郵件地址',
      __pwd: '建立密碼',
      __repwd: '確認密碼',
      __btn: '註冊帳號',
      __link: '我已經是會員了',
      __alert: '請查看郵件，並點選信件內的驗證連結。',
      __alert_2: '註冊成功',
      __verified: '帳號驗證成功，我們將帶您探索個人頁面。',
      __google: '使用 Google 帳號登入',
      __facebook: '使用 Facebook 帳號登入'
    },

    in: {
      __title: '登入 A.V. Mapping',
      __email: '輸入您的電子郵件地址',
      __pwd: '輸入密碼',
      __forgetPwd: '忘記密碼？',
      __btn: '登入',
      __linkText1: '還不是會員嗎？',
      __linkText2: '立即加入吧！',
      __alert1: '欄位不可為空白',
      __alert2: '帳號密碼錯誤，請重新輸入。',
      __alert3: '您的電子郵件尚未驗證，請至信箱收取"info@avmapping.co"驗證信，並點選連結後啟用帳號。',
      __alert4: '請先登入',
      __google: '使用 Google 帳號登入',
      __facebook: '使用 Facebook 帳號登入'
    },

    forget: {
      __title: '變更密碼',
      __email: '輸入您的電子郵件地址',
      __btn: '送出',
      __alert: '重設密碼：我們將會傳送一個重設連結至您的電子郵件 以重置您的密碼。'
    },

    error: {
      __alert: '請解除封鎖第三方登入彈跳視窗，若仍無法執行，請使用無痕模式，以避免相關擴充應用程序設定影響。'
    }
  }
}
