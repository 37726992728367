<template>
  <v-dialog
    v-model="currentStatus"
    content-class="share-alert"
  >
    <div :class="$style.share">
      <div :class="$style.top">{{ $t('filmMusicMappingV2.step2.share.__title') }}</div>
      <div :class="$style.content">
        <input
          :value="reorgCode"
          ref="share-input"
          type="text"
        >
        <div
          :class="$style.copy"
          @click="copyAuditionCode"
        >
          <i class="far fa-copy"></i>
          <div v-if="copied" :class="$style.hoverTips">
            <span>{{ $t('filmMusicMappingV2.step2.share.__copied') }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'AnalyzerShare',
  props: {
    code: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean,
      default: false
    },
    shareType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      copied: false,
      currentCode: '',
      currentStatus: false
    }
  },
  computed: {
    reorgCode () {
      const infix = this.shareType === 'music-library' ? '/#/audition/music-library/' : '/#/audition/'
      return `${process.env.VUE_APP_MEMBER_URL}${infix}${this.currentCode}`
    }
  },
  watch: {
    currentStatus () {
      if (this.currentStatus === false) {
        this.$emit('update:status')
      }
    },
    code () {
      this.currentCode = this.code
    },
    status () {
      this.currentStatus = this.status
      if (!this.currentStatus) this.copied = false
    }
  },
  methods: {
    copyAuditionCode () {
      navigator.clipboard.writeText(this.reorgCode)
      this.$refs['share-input'].select()
      this.copied = true
    }
  }
}
</script>

<style lang="scss" module>
@import '@/styles/scss/variables.scss';
@import '@/styles/scss/public-mixin.scss';

.share {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: auto;
  background-color: #313335;
  border-radius: 25px;

  .top {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    font-size: 20px;
    line-height: 24px;
    color: $white-0;

    user-select: none;
  }

  .content {
    display: flex;
    align-items: center;
    width: 100%;

    border-top: solid 3px $charcoal-grey;

    padding: {
      top: 15px;
      left: 25px;
      right: 25px;
      bottom: 15px;
    }

    input {
      width: 100%;
      letter-spacing: 0.03em;
      line-height: 1.7;
      color: #fff;
      white-space: pre-wrap;
    }
  }

  .copy {
    @include hover-tips;
    flex-shrink: 0;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    cursor: pointer;

    margin: {
      left: 15px;
    }

    .hoverTips {
      left: -20px;
      bottom: calc(100% + 10px)
    }
  }
}
</style>

<style lang="scss" scope>
.share-alert {
  width: 100%;
  max-width: 480px;
  overflow: visible;

  &.v-dialog {
    border-radius: 25px;
  }
}
</style>
